import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import SEO from 'components/seo';
import AppLayout from 'app/AppLayout/AppLayout';
import useStyles from './PrivacyPolicy.styles';

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  const items = [...Array(7).keys()].map(index => ({
    paragraphs: t(`routes.privacyPolicy.items.${index + 1}.paragraphs`),
    title: t(`routes.privacyPolicy.items.${index + 1}.title`),
  }));

  return (
    <AppLayout
      headerTransparent={false}
      subheader={{
        title: t('routes.privacyPolicy.title'),
      }}
    >
      <SEO
        description={t('routes.privacyPolicy.meta.description')}
        title={t('routes.privacyPolicy.meta.title')}
      />
      <Container className={classes.root} maxWidth="lg">
        {items.map(({ paragraphs, title }) => (
          <Box key={title} className={classes.item}>
            <Typography variant="h4">
              {title}
            </Typography>
            {Object.keys(paragraphs).map((p, i) => {
              const paragraph = paragraphs[p];
              return (
                <p>
                  <Typography key={i} align="justify" variant="body1">
                    {paragraph}
                  </Typography>
                </p>
              );
            })}
          </Box>
        ))}
      </Container>
    </AppLayout>
  );
};

export default PrivacyPolicy;
